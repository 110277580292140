import React, { useState, useContext } from 'react';
import { styled } from 'styles';
import MainLayout from 'components/MainLayout';
import Button from 'components/Button';
import Search from 'components/Search';
import ManageTeams from './ManageTeams';
import ManageUser from './ManageUser';
import InviteUser from './InviteUser';
import TeamFilter from './TeamFilter';
import UsersList from './UsersList';
import Context, { UserManagementProvider } from './state';

const UserManagementPage = () => {
  const [openManageModal, setOpenManageModal] = useState<boolean>(false);
  const [openInviteUser, setOpenInviteUser] = useState<boolean>(false);
  const { userModalData, setUserModalData, filters, setFilters } = useContext(Context);

  return (
    <MainLayout>
      <StyledUserManagementPage className="user-management">
        <div className="user-management__header">
          <h4 className="user-management__title">Team Management</h4>
          <Button className="user-management__invite-btn" onClick={() => setOpenInviteUser(true)}>
            Invite User
          </Button>
        </div>
        <Search
          value={filters.search}
          onChange={({ target }) => setFilters(v => ({ ...v, search: target.value }))}
        />
        <div className="team-controls">
          <TeamFilter />
          <Button
            className="user-management__manage-btn"
            onClick={() => setOpenManageModal(true)}
            secondary>
            Manage Teams
          </Button>
        </div>
        <UsersList />
        <ManageTeams open={openManageModal} onClose={() => setOpenManageModal(false)} />
        <ManageUser open={Boolean(userModalData)} onClose={() => setUserModalData(undefined)} />
        <InviteUser open={openInviteUser} onClose={() => setOpenInviteUser(false)} />
      </StyledUserManagementPage>
    </MainLayout>
  );
};

const StyledUserManagementPage = styled.div`
  display: flex;
  flex-direction: column;
  .team-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    align-items: baseline;
  }
  .user-management {
    &__header {
      display: flex;
      justify-content: space-between;
      margin: 0 0 28px;
      width: 100%;
    }
    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
      color: black;
      margin: 0;
    }
    &__invite-btn {
      min-width: 100px;
    }
    &__manage-btn {
      margin-left: auto;
      min-width: 110px;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .team-controls {
      overflow-x: auto;
      width: 1145px;
      padding-right: 10px;
    }
    .user-management {
      &__header {
        margin: 0 0 16px;
      }
      &__manage-btn {
        margin-left: 16px;
      }
    }
  }
`;

export default props => (
  <UserManagementProvider>
    <UserManagementPage {...props} />
  </UserManagementProvider>
);
