import React, { useContext } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import Context from './state';

const TeamFilter = () => {
  const { teams, filters, setFilters } = useContext(Context);

  return (
    <StyledTeamFilter className="team-filter">
      <div
        className={clsx('team-filter__item', { active: !filters.team })}
        key="all"
        onClick={() => setFilters(v => ({ ...v, team: undefined }))}>
        All
      </div>
      {teams.map(({ id, name }) => (
        <div
          className={clsx('team-filter__item', { active: id === filters.team })}
          key={id}
          onClick={() => setFilters(v => ({ ...v, team: id }))}>
          {name}
        </div>
      ))}
    </StyledTeamFilter>
  );
};

export default React.memo(TeamFilter);

const StyledTeamFilter = styled.div`
  display: flex;
  margin: 0 0 12px;
  font-size: 14px;
  line-height: 24px;

  .team-filter {
    &__item {
      font-weight: 600;
      & + .team-filter__item {
        margin-left: 16px;
      }
      &:not(.active) {
        opacity: 0.6;
        cursor: pointer;
      }
      &.active {
        border-bottom: 2px solid ${props => props.theme.colors.red};
      }
    }
  }
`;
