import React, { useContext, useState, useMemo } from 'react';

import { styled } from 'styles';
import notify from 'notify';
import Button from 'components/Button';
import Dropdown, { DropdownOption } from 'components/Dropdown';
import Modal, { ModalProps } from 'components/Modal';
import Tag from 'components/Tag';
import TeamManagerContext from './state';
import UserManagementContext from '../state';

const AddTeamMemberModal: React.FC<ModalProps> = ({ onClose, ...props }) => {
  const { openTeamMemberModal, getUserOptions, addTeamMember } = useContext(TeamManagerContext);
  const { users, reload } = useContext(UserManagementContext);
  const [selected, setSelected] = useState<number[]>([]);

  const userOptions = getUserOptions(users.filter(user => !user.teamId));

  const count = selected.length;
  const displayText = useMemo(() => {
    return count && count > 1
      ? `${count} members`
      : userOptions.find(user => user.value === selected[0])?.text ?? '';
  }, [users, count]);

  const handleConfirm = async () => {
    try {
      const requests = selected.map(userId => {
        const user = users.find((user: TeamMember) => user.id === userId);

        if (user && openTeamMemberModal)
          return addTeamMember({ ...user, teamId: openTeamMemberModal });
      });

      await Promise.all(requests);
      handleClose;
      reload();
    } catch (err) {
      notify(err.message);
    }
  };

  const handleClose = () => {
    onClose?.();
    setSelected([]);
  };

  return (
    <StyledTeamModal
      className="add-user-modal"
      modalTitle="Add New Member"
      onClose={handleClose}
      {...props}>
      <Dropdown
        label="Team Members"
        placeholder="Select"
        options={userOptions}
        value={selected}
        onChange={setSelected}
        text={displayText}
        multiple
        search={userOptions.length > 0}
      />
      <div className="user-tags">
        {selected.map(item => (
          <Tag key={item} onDelete={() => setSelected(v => v.filter(v => !v))}>
            {userOptions.find((user: DropdownOption) => user.value === item)?.text}
          </Tag>
        ))}
      </div>
      <Button className="add-user-btn" disabled={count < 1} onClick={handleConfirm}>
        Add
      </Button>
    </StyledTeamModal>
  );
};

export default React.memo(AddTeamMemberModal);

const StyledTeamModal = styled(Modal)`
  .modal-content {
    max-width: 324px;
  }
  .add-user-btn {
    width: 100%;
  }
  .user-tags {
    margin: 0 0 8px;
    display: flex;
    flex-flow: wrap;

    & > * {
      margin: 0 8px 8px 0;
    }
  }
  .dropdown {
    margin: 0 0 16px;
  }
`;
