import React, { useState, useContext, useEffect, useCallback } from 'react';

import { styled } from 'styles';
import notify from 'notify';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';
import Input from 'components/Input';
import Modal, { ModalProps } from 'components/Modal';
import validateEmail from 'utils/validateEmail';
import Context from './state';
import { inviteUser } from './api';

type FormState = {
  email: string;
  teamId?: number;
};

const initialFormState: FormState = {
  email: ''
};

const InviteUser: React.FC<ModalProps> = ({ onClose, ...props }) => {
  const [values, setValues] = useState<FormState>(initialFormState);
  const { teamOptions, reload } = useContext(Context);

  const isValid = validateEmail(values.email);

  const clearForm = () => {
    setValues(initialFormState);
  };

  const handleSubmit = async () => {
    if (!isValid) return;

    try {
      await inviteUser(values);
      notify(`Invitation was sent.`);
      handleClose();
      reload();
    } catch (err) {
      notify(err.response);
    }
  };

  const handleClose = useCallback(() => {
    onClose?.();
    clearForm();
  }, []);

  return (
    <StyledTeamModal
      className="invite-modal"
      modalTitle="Invite User"
      onClose={handleClose}
      {...props}>
      <Input
        label="Invite Email"
        value={values.email}
        onChange={({ target }) => setValues(v => ({ ...v, email: target.value }))}
      />
      <Dropdown
        label="Team"
        placeholder="Select"
        options={teamOptions}
        value={values.teamId}
        onChange={(teamId: number) => setValues(v => ({ ...v, teamId }))}
      />
      <Button className="send-invite-btn" disabled={!isValid} onClick={handleSubmit}>
        Send Invite
      </Button>
    </StyledTeamModal>
  );
};

export default React.memo(InviteUser);

const StyledTeamModal = styled(Modal)`
  .input,
  .button {
    width: 100%;
  }
  .input ~ * {
    margin-top: 16px;
  }
`;
