import slugify from 'slugify';
import keysToCamel from 'utils/keysToCamel';
import { SITE_URL } from 'consts';

// renames keys to camelCase and add team name
export const mapUser = (item): TeamMember => {
  return {
    ...keysToCamel(item),
    teamName: item.team?.name
  };
};

// map users
export const mapUsers = (users): TeamMember[] => {
  return users.map(mapUser);
};

// renames keys to camelCase and add members
export const mapTeams = (teams): Team[] => {
  return teams.map(({ members, ...item }) => ({
    ...keysToCamel(item),
    members: members.map(member => member.id)
  }));
};

// map user listings
export const mapProjects = (listings): UserProject[] => {
  return listings.map(listing => ({
    id: listing.id,
    name: listing.address1,
    status: listing.status,
    type: listing.type,
    url: `${SITE_URL}/listing/${listing.id}/${slugify(listing.address1, {
      lower: true,
      strict: true
    })}`
  }));
};
