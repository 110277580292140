import React, { useContext, useState, useEffect, useMemo } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import notify from 'notify';
import useLayout from 'hooks/useLayout';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import ConfirmationDialog from 'components/ConfirmationDialog';
import Dropdown from 'components/Dropdown';
import Icon from 'components/Icon';
import Modal from 'components/Modal';
import SideMenu from 'components/SideMenu';
import Context from '../state';
import noPhoto from 'assets/images/avatar.jpg';
import { UserRoleTypes } from '../types';
import { promoteTeamMember, deleteUser } from '../api';

type Rights = {
  admin: boolean;
  teamLead: boolean;
  active: boolean;
};

const ManageUser = props => {
  const layout = useLayout();
  const { teamOptions, reload, userModalData: data, listings } = useContext(Context);
  const [team, setTeam] = useState<number | null>();
  const [rights, setRights] = useState<Rights>({
    admin: false,
    teamLead: false,
    active: false
  });
  const [openDeleteUser, setOpenDeleteUser] = useState<boolean>(false);

  useEffect(() => {
    setRights({
      admin: data?.roleId === UserRoleTypes.admin,
      teamLead: data?.roleId === UserRoleTypes.teamLead,
      active: Boolean(active)
    });
    setTeam(data?.teamId);
  }, [data]);

  if (!data) return null;

  const { id, name, email, active, avatar } = data;

  const updateRights = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setRights({ ...rights, [name]: checked });
  };

  const onSubmit = async () => {
    try {
      const userRole = rights.admin
        ? UserRoleTypes.admin
        : rights.teamLead
        ? UserRoleTypes.teamLead
        : UserRoleTypes.user;
      const payload = {
        roleId: userRole,
        email: data.email,
        active: rights.active,
        teamId: team
      };
      await promoteTeamMember(payload);
      notify('User updated successfully.');
      reload();
    } catch (err) {
      notify(err.message);
    }
    props.onClose();
  };

  const onDelete = async () => {
    try {
      await deleteUser(id);
      notify('User deleted successfully.');
      setOpenDeleteUser(false);
      props.onClose?.();
      reload();
    } catch (err) {
      notify(err.message);
    }
  };

  const contentComponent = (
    <StyledManageUser className="manage-user">
      <div className="user-info">
        <div
          className="user-info__photo"
          style={{ backgroundImage: `url(${avatar || noPhoto})` }}></div>
        <div className="user-info__details">
          <h3 className="user-info__title">{name}</h3>
          <a href={`mailto:${email}`} className="user-info__link">
            {email}
          </a>
          <Checkbox
            label="Admin Rights"
            checked={rights.admin}
            disabled={rights.teamLead}
            name="admin"
            onChange={updateRights}
          />
          <Checkbox
            label="Active Account"
            checked={rights.active}
            name="active"
            onChange={updateRights}
          />
          <Checkbox
            label="Team Lead"
            checked={rights.teamLead}
            disabled={rights.admin}
            name="teamLead"
            onChange={updateRights}
          />
        </div>
        <Button secondary className="delete-user-btn" onClick={() => setOpenDeleteUser(true)}>
          <Icon name="delete-user" />
          Delete this User
        </Button>
      </div>
      <div className="manage-team">
        <Dropdown
          label="Team"
          placeholder="Select"
          options={teamOptions}
          value={team}
          onChange={setTeam}
        />
        <Button className="save-btn" onClick={onSubmit}>
          Save
        </Button>
      </div>
      <div className="user-projects scrollable-fct">
        <div className="user-projects__header user-projects__item">
          <div className="user-projects__item-info user-projects__item-name">Recent Project</div>
          <div className="user-projects__item-info">Type</div>
          <div className="user-projects__item-info">Status</div>
          <div className="user-projects__item-info">Link</div>
        </div>
        {listings[data.id]?.map(listing => (
          <div className="user-projects__item" key={listing.id}>
            <div className="user-projects__item-info user-projects__item-name">{listing.name}</div>
            <div className="user-projects__item-info">{listing.type}</div>
            <div
              className={clsx('user-projects__item-info user-projects__item-status', {
                active: listing.status.toLowerCase() === 'active'
              })}>
              {listing.status}
            </div>
            <a
              className="user-projects__item-info user-projects__item-link"
              title={listing.name}
              href={listing.url}
              target="_blank">
              View page →
            </a>
          </div>
        ))}
      </div>
    </StyledManageUser>
  );

  const getContent = () => {
    switch (layout) {
      case 'mobile':
        return (
          <StyledModal renderContainer={StyledModalContainer} {...props}>
            {contentComponent}
          </StyledModal>
        );
      default:
        return <StyledSideMenu {...props}>{contentComponent}</StyledSideMenu>;
    }
  };

  return (
    <>
      {getContent()}
      <ConfirmationDialog
        modalTitle="Delete User"
        open={openDeleteUser}
        onSubmit={onDelete}
        onClose={() => setOpenDeleteUser(false)}>
        <p>Are you sure you want to remove this user? This action can’t be undone.</p>
      </ConfirmationDialog>
    </>
  );
};

export default React.memo(ManageUser);

const StyledSideMenu = styled(SideMenu)`
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`;

const StyledManageUser = styled.div`
  width: 728px;
  font-size: 12px;
  line-height: 16px;

  .user-info {
    display: flex;
    align-items: flex-end;
    margin-bottom: 24px;

    &__photo {
      margin: 0 16px 0 0;
      width: 124px;
      height: 124px;
      border-radius: ${props => props.theme.misc.borderRadius};
      flex-shrink: 0;
      background-size: cover;
      background-position: center;
    }
    &__title {
      margin: 0 0 4px;
      font-size: 16px;
      line-height: 24px;
    }
    &__link {
      margin-bottom: 8px;
      color: ${props => props.theme.colors.grayDark};
      text-decoration: none;
    }
    &__details {
      display: flex;
      flex-flow: column;
    }
    .checkbox {
      margin-top: 8px;
    }
    .delete-user-btn {
      margin-left: auto;
      .icon {
        margin-right: 4px;
      }
    }
  }
  .manage-team {
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;
    .dropdown {
      min-width: 300px;

      & ~ * {
        margin-left: 16px;
      }
    }
    .button {
      min-width: 96px;
      height: 40px;
    }
  }
  .user-projects {
    &__header {
      color: ${props => props.theme.colors.grayDark};
    }
    &__item {
      display: flex;
      align-items: center;
      padding: 16px 0;
      &-info {
        flex: 1;
        &:last-child {
          flex: 0.75;
        }
      }
      &-name {
        flex: 2.5;
      }
      &-status.active {
        color: ${props => props.theme.colors.lightGreen};
      }
      &-link {
        color: ${props => props.theme.colors.red};
        font-weight: 500;
        text-decoration: none;
      }
      & ~ * {
        border-top: 1px solid ${props => props.theme.colors.seashell};
      }
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .user-projects {
      padding: 0 4px 0 0;
      max-height: calc(100vh - 288px);
      overflow: auto;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    display: flex;
    flex-flow: column;
    position: relative;
    .user-info {
      flex-flow: column;
      align-items: flex-start;
      &__photo {
        margin: 0 0 16px 0;
      }
      .delete-user-btn {
        margin: 16px 0 0;
      }
    }
    .manage-team {
      flex-flow: column;
      align-items: flex-start;
      .dropdown {
        width: 100%;
      }
      .save-btn {
        width: 100%;
        margin: 16px 0 0;
      }
    }
    .user-projects {
      padding: 0;
      &__header {
        & > *:not(:first-of-type) {
          display: none;
        }
      }
      &__item {
        flex-flow: column;
        align-items: flex-start;
        &-info ~ * {
          margin-top: 8px;
        }
      }
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    width: 100%;
    min-width: unset;
    border-radius: 0;

    .dropdown {
      width: 100%;
      min-width: unset;
    }
  }
`;

const StyledModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 1000;

  .modal-bg {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
  }
`;
