export enum UserRoles {
  admin = 'Admin',
  team_lead = 'Team Lead',
  user = 'User'
}

export enum UserRoleTypes {
  admin = 1,
  teamLead = 2,
  user = 3
}
