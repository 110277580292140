import React, { useState } from 'react';
import clsx from 'clsx';

import { styled } from 'styles';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Avatar from './Avatar';
import UserDetails from './UserDetails';
import { UserRoles } from './types';

interface UsersListItemProps {
  data: TeamMember;
  onEdit: () => void;
  onOpen?: () => void;
}

const UsersListItem: React.FC<UsersListItemProps> = ({ data, onEdit, onOpen }) => {
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const { avatar, name, email, teamName, activeTransactions, closedTransactions, role } = data;

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onOpen?.();
    onEdit();
  };

  const handleOpenClick = () => {
    onOpen?.();
    setOpenDetails(v => !v);
  };

  return (
    <>
      <StyledUsersListItem
        className={clsx('users-list__item', { 'users-list__item__open': openDetails })}
        onClick={handleOpenClick}>
        <div className="user-info users-list__item-name">
          <Icon name="plus-mini" fill="#000000" />
          <Avatar src={avatar} className="user-img" />
          {name || email}
          {role !== 'user' && <span className="badge">{UserRoles[role]}</span>}
        </div>
        <div className="user-info users-list__item-active">{activeTransactions || '-'}</div>
        <div className="user-info users-list__item-closed">{closedTransactions || '-'}</div>
        <div className="user-info users-list__item-team">
          <span>{teamName}</span>
        </div>
        <div className="user-info users-list__item-settings">
          <Button simple onClick={handleEditClick}>
            <Icon name="gear" />
          </Button>
        </div>
      </StyledUsersListItem>
      {openDetails && <UserDetails data={data} />}
    </>
  );
};

export default React.memo(UsersListItem);

const StyledUsersListItem = styled.div`
  display: flex;
  .icon {
    flex-shrink: 0;
    transition: all 300ms;
  }
  &.users-list__item__open {
    .icon-plus-mini {
      transform: rotate(45deg);
      fill: ${props => props.theme.colors.red};
    }
  }
  .users-list__item-settings {
    .button {
      width: 24px;
      height: 24px;
    }
  }
  .user-img {
    margin: 0 12px 0 15px;
  }
  .badge {
    background: ${props => props.theme.colors.seashell};
    color: ${props => props.theme.colors.red};
    border-radius: ${props => props.theme.misc.borderRadius};
    padding: 2px 4px;
    margin: 0 0 0 8px;
    font-size: 12px;
    line-height: 16px;
  }
`;
