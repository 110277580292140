import React, { useState, useEffect, useMemo, Dispatch, useCallback } from 'react';

import notify from 'notify';
import { DropdownOption } from 'components/Dropdown';
import {
  getUsersDetailed,
  getTeams as getTeamsRequest,
  getUserListings as getUserListingsRequest
} from './api';

type Filters = {
  team?: number;
  search?: string;
};

interface UserManagementValues {
  users: TeamMember[];
  teams: Team[];
  filters: Filters;
  teamOptions: DropdownOption[];
  setFilters: Dispatch<React.SetStateAction<Filters>>;
  userModalData?: TeamMember;
  setUserModalData: Dispatch<React.SetStateAction<TeamMember | undefined>>;
  getUserListings: (value: number) => void;
  listings: Record<number, UserProject[]>;
  reload: () => void;
}

const UserManagement = React.createContext({} as UserManagementValues);

export const UserManagementProvider = ({ children }) => {
  const [users, setUsers] = useState<TeamMember[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [filters, setFilters] = useState<Filters>({});
  const [userModalData, setUserModalData] = useState<TeamMember | undefined>();
  const [listings, setListings] = useState<Record<number, UserProject[]>>({});

  const teamOptions = useMemo(
    () =>
      teams.map((team: Team) => ({
        text: team.name,
        value: team.id
      })),
    [teams]
  );

  const getUsers = async () => {
    try {
      const users = await getUsersDetailed();
      setUsers(users);
    } catch (err) {
      notify(err.message);
    }
  };

  const getTeams = async () => {
    try {
      const teams = await getTeamsRequest();
      setTeams(teams);
    } catch (err) {
      notify(err.message);
    }
  };

  const getUserListings = async (userId: number) => {
    try {
      const listings = await getUserListingsRequest(userId);
      setListings(v => ({ ...v, [userId]: listings }));
    } catch (err) {
      notify(err.message);
    }
  };

  const reload = () => {
    getUsers();
    getTeams();
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    const teamIdx = teams.findIndex(team => team.id === filters.team);
    if (teamIdx < 0) setFilters(v => ({ ...v, team: undefined }));
  }, [filters.team, teams]);

  const values: UserManagementValues = {
    users,
    teams,
    filters,
    teamOptions,
    setFilters,
    userModalData,
    setUserModalData,
    listings,
    getUserListings,
    reload
  };

  return <UserManagement.Provider value={values}>{children}</UserManagement.Provider>;
};

export default UserManagement;
