import React, { useContext, useMemo } from 'react';

import { styled } from 'styles';
import UsersListItem from './UsersListItem';
import Context from './state';

const UsersList = () => {
  const {
    users,
    filters: { team, search },
    setUserModalData,
    listings,
    getUserListings
  } = useContext(Context);

  const filteredUsers = useMemo(() => {
    let result = [...users];
    const searchString = search?.toLocaleLowerCase() || '';
    if (team) result = result.filter(user => user.teamId === team);
    if (searchString) {
      result = result.filter(item => {
        return (
          item.name?.toLocaleLowerCase().includes(searchString) ||
          item.email?.toLocaleLowerCase().includes(searchString)
        );
      });
    }
    return result;
  }, [users, team, search]);

  return filteredUsers.length > 0 ? (
    <StyledUsersList className="users-list">
      <div className="users-list__header users-list__item">
        <div className="users-list__item-info users-list__item-name">User</div>
        <div className="users-list__item-info users-list__item-active">Active Transactions</div>
        <div className="users-list__item-info users-list__item-closed">Closed Transactions</div>
        <div className="users-list__item-info users-list__item-team">Team</div>
        <div className="users-list__item-info users-list__item-settings"></div>
      </div>
      {filteredUsers.map((user: TeamMember) => (
        <UsersListItem
          data={user}
          key={user.id}
          onEdit={() => setUserModalData(user)}
          onOpen={() => (listings[user.id] ? undefined : getUserListings(user.id))}
        />
      ))}
    </StyledUsersList>
  ) : null;
};

export default React.memo(UsersList);

const StyledUsersList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;

  .users-list {
    &__item {
      display: flex;
      flex-flow: wrap;
      padding: 16px 3px;

      & > * {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      & + .users-list__item {
        border-top: 1px solid ${props => props.theme.colors.seashell};
      }

      &:not(.users-list__header):hover {
        background: ${props => props.theme.colors.seashell};
        cursor: pointer;
      }
    }
    &__header {
      color: ${props => props.theme.colors.grayDark};
      &.users-list__item {
        padding: 12px 3px;
      }
    }
    &__item-name {
      flex: 2.5;
    }
    &__item-active {
      flex: 1.5;
    }
    &__item-closed {
      flex: 1;
    }
    &__item-team {
      flex: 1.5;
      overflow: hidden;
      & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &__item-settings {
      flex: 0.5;
      justify-content: flex-end;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    overflow-x: auto;
    width: 1145px;
    padding-right: 10px;
  }
`;
