import React from 'react';

import { styled } from 'styles';

const Badge = ({ children, ...props }) => <StyledBadge {...props}>{children}</StyledBadge>;

export default React.memo(Badge);

const StyledBadge = styled.span`
  background: #f1f1f1;
  color: #df1721;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
`;
