import queryString from 'query-string';
import { fetchWithToken } from 'utils/fetch';
import { checkSuccess } from 'utils/requests';
import { mapUsers, mapUser, mapTeams, mapProjects } from './mapping';

export async function getUsers(): Promise<TeamMember[]> {
  const response = await fetchWithToken('/users');
  const data = await response.json();

  checkSuccess(data);
  return data;
}

export async function getUsersDetailed(): Promise<TeamMember[]> {
  const response = await fetchWithToken('/users/detailed');
  const data = await response.json();

  checkSuccess(data);
  return mapUsers(data);
}

export async function getUserListings(userId: number): Promise<any> {
  const query = queryString.stringify({
    user_id: userId
  });
  const response = await fetchWithToken(`/listings?${query}`);
  const data = await response.json();

  checkSuccess(data);
  return mapProjects(data.listings);
}

export async function getTeams(): Promise<Team[]> {
  const response = await fetchWithToken('/teams');
  const data = await response.json();

  checkSuccess(data);
  return mapTeams(data);
}

export async function createTeam(payload: TeamPayload): Promise<Team> {
  const formData = {
    name: payload.name,
    members: payload.members
  };

  const response = await fetchWithToken('/teams', {
    method: 'POST',
    body: urlEncodeBody(formData)
  });

  const data = await response.json();

  checkSuccess(data);
  return data;
}

export async function deleteTeam(id: number): Promise<void> {
  await fetchWithToken(`/teams/${id}`, {
    method: 'DELETE'
  });
}

export async function promoteTeamMember(payload: EditTeamMemberPayload): Promise<TeamMember> {
  const formData = {
    email: payload.email,
    team_id: payload.teamId,
    role_id: payload.roleId,
    active: payload.active ? 1 : 0
  };

  const response = await fetchWithToken('/users/promote', {
    method: 'POST',
    body: urlEncodeBody(formData)
  });

  const data = await response.json();

  checkSuccess(data);
  return mapUser(data);
}

export async function editTeamMember(payload: EditTeamMemberPayload): Promise<TeamMember> {
  const formData = {
    email: payload.email,
    team_id: payload.teamId
  };

  const response = await fetchWithToken('/users/promote', {
    method: 'POST',
    body: urlEncodeBody(formData)
  });

  const data = await response.json();

  checkSuccess(data);
  return mapUser(data);
}

export async function deleteUser(id: number): Promise<any> {
  await fetchWithToken(`/users/${id}`, {
    method: 'DELETE'
  });
}

export async function inviteUser(payload: InviteUserPayload): Promise<void> {
  const formData = {
    email: payload.email,
    team_id: payload.teamId || null
  };

  await fetchWithToken('users/invite', {
    method: 'POST',
    body: urlEncodeBody(formData)
  });
}

const urlEncodeBody = (data: object = {}) => {
  const body = new URLSearchParams();
  Object.entries(data).forEach(([key, value]) => body.set(key, value));

  return body;
};

type InviteUserPayload = {
  email: string;
  teamId?: number;
};

export type TeamPayload = {
  name: string;
  members?: number[];
};

export type EditTeamMemberPayload = {
  email?: string;
  teamId?: number | null;
  roleId?: number | null;
  active?: boolean;
};
